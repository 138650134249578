import styled from "styled-components";
import typhography from "../../../theme/mixins/typhography";
import MEDIA from "../../../theme/helpers/mediaTemplates";

export const Tag = styled.div`
  border: 1px solid ${(props) => props.theme.default_halfUp};
  color: ${(props) => props.theme.default_darkerOne};
  display: inline-block;
  text-align: right;
  margin: 0px 0px 10px auto;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 0.9rem;
  ${typhography.overlines}
`;

export const SectionContainer = styled.div`
  padding: 32px 42px;
  border-radius: 32px;
  margin-bottom: 32px;
  max-width: ${(props) => props.theme.sizes.contentWideMaxWidth};
  background: linear-gradient(
    135deg,
    white,
    ${(props) => props.theme.default_lighterTwo}
  );
  border: 1px solid ${(props) => props.theme.default_halfUp};

  /* box-shadow: 5px 5px 7px #00000044,
    -2px -2px 2px ${(props) => props.theme.default_lighterOne}; */
  ${typhography.body}
  ${MEDIA.PHONE`
		padding:16px;

	`}
`;
