import React from 'react'
import * as Styled from './styled'


type LessonButtonBlock = {
  lessonHasQuiz: any;
  goNextLessonAction: any;
  isQuizVisible: any;
  goBackToTheoryAction: any;
  submitQuizAction: any;
  showQuizAction: any;
  isQuizCompleted: any;
  restartQuizAction: any;
  isLessonDone: any;
  undoLessonAction: any;
  className: string;
}
function LessonButtonBlock(props: LessonButtonBlock) {
  const {
    lessonHasQuiz,
    goNextLessonAction,
    isQuizVisible,
    goBackToTheoryAction,
    submitQuizAction,
    showQuizAction,
    isQuizCompleted,
    restartQuizAction,
    isLessonDone,
    undoLessonAction,
    className,
  } = props;

  if (!lessonHasQuiz) {
    return (<Styled.Actions className={className}>

      <Styled.CompleteButton onClick={goNextLessonAction}>
        Complete lesson & move on
      </Styled.CompleteButton>
      {isLessonDone && (<>
        <Styled.ButtonSecondary onClick={undoLessonAction}>
          Undo lesson
        </Styled.ButtonSecondary>



      </>)}
    </Styled.Actions>
    )
  }

  if (!isQuizVisible) {
    return (<Styled.Actions className={className}>
      <Styled.CompleteButton onClick={showQuizAction}>
        See quiz
      </Styled.CompleteButton>
    </Styled.Actions>
    )
  }

  if (!isQuizCompleted) {
    return (
      <Styled.Actions className={className}>

        <Styled.CompleteButton onClick={submitQuizAction}>
          Submit quiz
        </Styled.CompleteButton>

        <Styled.ButtonSecondary onClick={goBackToTheoryAction}>
          Back to theory
        </Styled.ButtonSecondary>
      </Styled.Actions>
    )
  }

  return (
    <Styled.Actions className={className}>

      <Styled.CompleteButton onClick={goNextLessonAction}>
        Next lesson
      </Styled.CompleteButton>

      <Styled.ButtonSecondary onClick={goBackToTheoryAction}>
        Back to theory
      </Styled.ButtonSecondary>



      {isLessonDone && (<>
        <Styled.ButtonSecondary onClick={undoLessonAction}>
          Undo lesson
        </Styled.ButtonSecondary>



      </>)}
    </Styled.Actions>
  )
}

export default LessonButtonBlock