import styled from "styled-components";
// import typhography from "../../../theme/mixins/typhography";
// import MEDIA from "../../../theme/helpers/mediaTemplates";


type InputCheckboxWrapperType = {
  disabled: boolean;
  showFeedback: boolean;
  isCorrectAnswer: boolean;
  checked: boolean;
}
export const InputCheckboxWrapper = styled.div<InputCheckboxWrapperType>`
  position: relative;
  display: inline-block;



  padding: 0.5rem 0.4rem;
  background ${(props) =>
    props.disabled
      ? props.theme.default_primary
      : props.theme.default_lighterOne};
  box-shadow:none;
  border-radius:40px;
  border: 2px solid ${(props) =>
    props.showFeedback === true
      ? props.isCorrectAnswer
        ? `${props.theme.staticColors.success}`
        : `${props.theme.staticColors.error}`
      : `${props.theme.default_lighterOne}`};

  display:flex;
  align-items:center;
  padding-right:25px;
  input[type="radio"]{
    visibility:hidden;
  }


  ${(props) =>
    props.showFeedback === true
      ? props.isCorrectAnswer
        ? `
        border: 2px solid ${props.theme.staticColors.success};
        box-shadow: 0px 0px 3px ${props.theme.staticColors.success};`
        : `
        border: 2px solid ${props.theme.staticColors.error};
        box-shadow: 0px 0px 3px ${props.theme.staticColors.error};`
      : props.checked
        ? `
        border:2px solid ${props.theme.staticColors.selected};
        background-color: ${props.theme.default_halfUp}
      `
        : `
        border: 2px solid ${props.theme.default_primary};
        `};
  
  input[type="radio"]:checked{
    visibility:hidden;
  }

  &::before {
    content: "";
    position: absolute;
    right: 0.6rem;
    top: 50%;
    transform: translateY(-50%);
    height: 15px;
    width: 15px;
    border-radius: 30px;
    border: 1px solid white;
    ${(props) =>
    props.showFeedback === true
      ? props.isCorrectAnswer
        ? `
          box-shadow: 0px 0px 3px ${props.theme.staticColors.success};
          background: ${props.theme.staticColors.success};
      `
        : `
          box-shadow: 0px 0px 3px ${props.theme.staticColors.error};
          background: ${props.theme.staticColors.error};
      `
      : props.checked
        ? ` 
        background: ${props.theme.staticColors.selected};
        `
        : ` 
        background: ${props.theme.default_primary};
        `}
  }

`;

export const Label = styled.label`
  display: block;
  font-size: 0.8rem;
  margin-bottom: 0.3rem;
`;

export const Feedback = styled.div`
  display: block;
  margin-top: 0.3rem;
  * {
    font-size: 0.7rem;
    line-height: 1;
    margin-top: 0;
    color: ${({ theme }) => theme.default_darkerTwo};
  }
`;
