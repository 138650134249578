import React, { useState } from "react";
import * as Styled from "./InputTextGroup.styled";
import InputText from "../InputText/InputText";
import matchExactAnswer from "../../utils/stringMatch";
import * as SharedStyled from "../../Quiz.styled";


type InputTextGroupType = {
  feedbackMode: any;
  onChange: any;
  title: any;
  exactAnswer: any;
  onBlur: any;
  linkedResources: any;
  selectedValue: string[];
}

const ImputTextGroup = (props: InputTextGroupType) => {
  const {
    // updateAnswer,
    feedbackMode,
    onChange,
    title,
    exactAnswer,
    onBlur,
    selectedValue,
    linkedResources,
  } = props;

  const questionPieces = title?.split("$") as string[];
  const [answerArray, set_answerArray] = useState<string[]>(
    selectedValue ? selectedValue : questionPieces ? questionPieces.map((e) => "").slice(0, -1) : [""],
  );

  if (!onChange || !title || !exactAnswer) {
    return null;
  }

  const correctAnswerArray = exactAnswer && exactAnswer.split(",").length > 0 ? exactAnswer.split(",") : [exactAnswer];
  const isQuestionCorrect =
    // TODO: what the fuck is answer array seeming an string and an array ?? Here added [0] to stop typescript from complaining, but there is an issue for sure.
    correctAnswerArray && matchExactAnswer(correctAnswerArray, answerArray)
      ? true
      : false;

  if (!answerArray) return null;
  return (
    <SharedStyled.QuizQuestionContainer
      className={"c-input-text-group"}
      isQuestionCorrect={isQuestionCorrect}
      feedbackMode={feedbackMode}
    >
      <Styled.TextGroupWrapper>
        {questionPieces.map((q, index) => (
          <React.Fragment key={index}>
            <div> {q}</div>

            <div>
              {index !== questionPieces.length - 1 && (
                <InputText
                  // TODO: DO we need the next 3 lines?
                  placeholder={"placeholder"}
                  label={"Input Label"}
                  // feedback={"this is feedback"}
                  anchor={"input-box"}
                  showFeedback={feedbackMode}
                  isCorrect={matchExactAnswer(
                    answerArray[index],
                    correctAnswerArray[index],
                  )}
                  onBlur={onBlur}
                  // disabled={false}
                  value={answerArray[index]}
                  callback={(changedValue: string) => {
                    const newArray = answerArray.map((q, i) =>
                      i === index ? changedValue : q,
                    );
                    set_answerArray(newArray);
                    onChange(newArray);
                  }}
                />
              )}
            </div>
          </React.Fragment>
        ))}
      </Styled.TextGroupWrapper>

      <Styled.TextGroupWrapper>
        {feedbackMode &&
          correctAnswerArray &&
          questionPieces.map((q, index) => (
            <p key={`${q}-${index}`}>
              {index !== 0 && <strong>{correctAnswerArray[index - 1]}</strong>}
              {q}
            </p>
          ))}
      </Styled.TextGroupWrapper>
    </SharedStyled.QuizQuestionContainer>
  );
};

export default ImputTextGroup;
