import styled from "styled-components";
// import { theme } from "../../theme/variables";
import MEDIA from "../../theme/helpers/mediaTemplates";

export const PurchasedVideoCourseGridItemWrapper = styled.div`
  background: white;
  padding: 1rem;
  width: 100%;
  display: flex;
  border-radius: 1rem;
  margin-bottom: 1rem;
  border: 1px solid ${(props) => props.theme.default_halfUp};
  ${MEDIA.PHONE`
    flex-direction:column;
  `};
`;

export const Body = styled.div`
  padding: 1rem;
  flex-basis: 1;
  flex-grow: 1;
`;
export const Title = styled.h4`
  font-size: 1.5rem;
`;

export const ProgressBar = styled.div`
  background: gray;
  height: 0.5rem;
  display: block;
  width: 100%;
  position: relative;
  border-radius: 1rem;
  &::after {
    content: "";
    position: absolute;
    background: green;
    border-radius: 1rem;
    left: 0;
    top: 0;
    bottom: 0;
    right: ${(props) => 100 - props.progress}%;
  }
`;
export const TileImage = styled.div`
  flex-basis: 1;
  border-radius: 0.5rem;
  /* min-height: 200px; */
`;
