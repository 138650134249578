import React from "react";
import { Link } from "gatsby";
import {
  LessonGridItemWrapper,
  Title,
  BadgeContainer,
  HasVideoBadge,
  HasQuizBadge,
  HasAttachmentBadge,
} from "./LessonGridItem.styled";

import HasVideoIcon from "../../assets/icons/has-video.svg";
import HasQuizIcon from "../../assets/icons/has-quiz.svg";
import HasFileIcon from "../../assets/icons/has-file.svg";
// TODO: use this to display nice icons next to the tiles with the content

type LessonGridItem = {
  contentful_id: string;
  title: string;
  hasVideo: boolean;
  hasFile: boolean;
  hasQuiz: boolean;
  isCompleted: boolean;
}

const LessonGridItem = (props: LessonGridItem) => {
  const {
    contentful_id,
    title,
    isCompleted,
    hasVideo,
    hasQuiz,
    hasFile,
  } = props;

  return (
    <LessonGridItemWrapper
      $isCompleted={isCompleted}
      as={Link}
      to={`lesson/${contentful_id}`}
    >
      <Title>{title}</Title>
      <BadgeContainer>
        {hasVideo && (
          <HasVideoBadge>
            <HasVideoIcon />
          </HasVideoBadge>
        )}
        {hasQuiz && (
          <HasQuizBadge>
            <HasQuizIcon />
          </HasQuizBadge>
        )}
        {hasFile && (
          <HasAttachmentBadge>
            <HasFileIcon />
          </HasAttachmentBadge>
        )}
      </BadgeContainer>
    </LessonGridItemWrapper>
  );
};

export default LessonGridItem;
