import styled, { keyframes } from "styled-components";

export const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-height: 60vh;
`;

const loadingAnimation = keyframes`
0% {
  opacity: 0.2;
}
50% {
  opacity: 1;
}
100% {
  opacity: 0.2;
}
`;
export const LoadingMessage = styled.h2`
  animation: 2s ${loadingAnimation} infinite alternate;
`;
