import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { useAppContext } from "../../../context/appProvider";
import { getData, postData } from "../../../utils/helpers";
import {
  CommentCard,
  CommentActions,
  ResponseActions,
} from "./CommentsAdmin.styled";

const CommentReponseForm = ({ commentId, postId }) => {
  const ctx = useAppContext();
  const { state, getAccessTokenSilently } = ctx;
  const [response, set_response] = useState("");
  const postResponse = async (e) => {
    e.preventDefault();
    const token = await getAccessTokenSilently();
    // const response = await postData({
    await postData({
      url: `${process.env.GATSBY_BACKEND_URL}/comments/post-comment-admin`,
      data: {
        comment: response,
        name: state.user.user.email,
        respondsTo: commentId,
        currentUrl: window.location.href,
        postId: postId,
      },
      token,
    });
  };
  return (
    <ResponseActions>
      <form onSubmit={postResponse}>
        <textarea
          rows={5}
          value={response}
          onChange={(e) => set_response(e.target.value)}
        ></textarea>
        <button type="submit">Publish response</button>
      </form>
    </ResponseActions>
  );
};

const CommentCardComponent = ({ comment }) => {
  // TODO: I have to find a way of making sure the ID of the user is acessible here. NOT in the graphql
  const ctx = useAppContext();
  const { getAccessTokenSilently } = ctx;
  const [showForm, set_showForm] = useState(false);
  const publishComment = async () => {
    const token = await getAccessTokenSilently();
    // const response = await postData({
    await postData({
      url: `${process.env.GATSBY_BACKEND_URL}/comments/approve-comment`,
      data: {
        commentId: comment.id,
        commentAuthorId: comment.authorEmail,
      },
      token,
    });
  };
  const hideComment = async () => {
    const token = await getAccessTokenSilently();
    // const response = await postData({
    await postData({
      url: `${process.env.GATSBY_BACKEND_URL}/comments/hide-comment`,
      data: {
        commentId: comment.id,
        commentAuthorId: comment.authorEmail,
      },
      token,
    });
  };
  return (
    <CommentCard>
      <div>{comment.comment}</div>
      <div>
        <div>Author name: {comment.author}</div>
        <div>Author email: {comment.authorEmail}</div>
        <div>{comment.status}</div>
        <div>{comment.dateAdded}</div>
        {comment.respondsTo && <div>Is response</div>}
        <div>{comment.id}</div>
        {comment.isByAutor && <div>Is by author</div>}
        <div>{decodeURIComponent(comment.currentUrl)}</div>
        <div>{comment.postId}</div>
      </div>
      <CommentActions>
        <button
          disabled={comment.status === "published"}
          onClick={publishComment}
        >
          Publish comment
        </button>
        <button disabled={comment.status === "hidden"} onClick={hideComment}>
          Hide comment
        </button>
        <button onClick={() => set_showForm(true)}>Reply</button>
      </CommentActions>
      {showForm && (
        <CommentReponseForm commentId={comment.id} postId={comment.postId} />
      )}
    </CommentCard>
  );
};

const CommentsAdmin = () => {
  const ctx = useAppContext();
  const { state, getAccessTokenSilently } = ctx;
  const [arrayOfComments, set_arrayOfComments] = useState([]);
  const [statusToFilterComments, set_statusToFilterComments] =
    useState("draft");

  const commentStatusArray = useMemo(() => {
    const arrayOfStatus = [];
    arrayOfComments.forEach((comment) => arrayOfStatus.push(comment.status));

    return [...new Set(arrayOfStatus)];
  }, [arrayOfComments]);

  useEffect(() => {
    const fetchData = async () => {
      const token = await getAccessTokenSilently();
      const apiResponse = await getData({
        url: `${process.env.GATSBY_BACKEND_URL}/comments/get-all-comments`,
        token,
      });
      if (apiResponse) {
        set_arrayOfComments(apiResponse.comments);
      }
    };
    fetchData();
  }, [state]);
  return (
    <div>
      <div>
        Filters:
        <select onChange={(e) => set_statusToFilterComments(e.target.value)}>
          {commentStatusArray.map((status, index) => (
            <option
              key={status}
              value={status}
              defaultValue={index === 0 ? true : false}
            >
              {status}
            </option>
          ))}
        </select>
      </div>
      {arrayOfComments &&
        arrayOfComments.length > 0 &&
        arrayOfComments
          .filter((c) => c.status === statusToFilterComments)
          .map((comment, i) => (
            <CommentCardComponent key={i} comment={comment} />
          ))}
    </div>
  );
};

export default CommentsAdmin;
