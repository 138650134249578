// Here we manage the UI & it's states, nothing async, no side effects.

type stateType = {
  isQuizVisible: boolean;
  isQuizCompleted: boolean;
  isLessonDone: boolean;
  lessonHasQuiz: boolean;
  lessonContent: any;
  lessonHasFiles: boolean;
  isLoading: boolean;
  isQuizLoading: boolean;
}


type undoLesson = {
  type: "UNDO-LESSON",
}

type setQuizLoading = {
  type: "SET-QUIZ-LOADING-STATUS",
  payload: boolean
}
type setLessonDone = {
  type: "SET-LESSON-DONE",
}
type setLessonLoading = {
  type: "SET-LESSON-LOADING",
}

type setLessonContent = {
  type: "SET-LESSON-CONTENT",
  payload: any;
}

type setLessonHasQuiz = {
  type: "SET-LESSON-HAS-QUIZ"
}

type showQuiz = {
  type: "SHOW-QUIZ",
}

type returnToQuiz = {
  type: "SHOW-LESSON",
}

type quizFeedback = {
  type: "QUIZ-ADD-FEEDBACK",
  payload: { message: string }
}

type resetQuiz = {
  type: "RESET-QUIZ",
  payload: {
    quizId: string;
  }
}

type submitQuiz = {
  type: "SUBMIT-QUIZ";
  payload: {
    token: string;
    lessonId: string;
    answerArray: string[];
    currentUrl: string;
    href: string;
  }
}

type undoQuiz = {
  type: "UNDO-QUIZ",
  payload: {
    quizId: string,
  }
}

type setLessonCompleted = {
  type: "SET-LESSON-COMPLETED"
}

type setQuizDone = {
  type: "SET-QUIZ-AS-DONE"
}

type lessonReducerType =
  showQuiz
  | resetQuiz
  | quizFeedback
  | submitQuiz
  | returnToQuiz
  | undoLesson
  | undoQuiz
  | setQuizDone
  | setLessonCompleted
  | setLessonHasQuiz
  | setLessonContent
  | setLessonDone
  | setLessonLoading
  | setQuizLoading;


export const lessonReducer = (
  lessonState: stateType, action: lessonReducerType) => {

  switch (action.type) {

    case "SHOW-QUIZ": {
      return {
        ...lessonState,
        isQuizVisible: true,
        // isQuizCompleted: false,
      }
    }
    case "SET-QUIZ-LOADING-STATUS": {
      return {
        ...lessonState,
        isQuizLoading: true,
      }
    }
    case "SET-QUIZ-AS-DONE": {
      console.log("Quiz is completed")
      return {
        ...lessonState,
        isQuizLoading: false,
        isQuizCompleted: true,
      }
    }
    case "SET-LESSON-CONTENT": {
      return {
        ...lessonState,
        lessonContent: action.payload,
        lessonHasQuiz: action.payload.fields.quizQuestions ? true : false,
        lessonHasFiles: action.payload.fields.attachment ? true : false,
        isLoading: false,
        // We init as undone, in the use effect in the lesson, will be fired later if is done
        // isQuizCompleted: false,

      }
    }
    case "SET-LESSON-LOADING": {
      return {
        ...lessonState,
        isLoading: true,
      }
    }
    case "SET-LESSON-DONE": {
      return {
        ...lessonState,
        isLessonDone: true,
        isQuizLoading: false,
        isQuizCompleted: true,
      }
    }
    case "SET-LESSON-HAS-QUIZ": {
      return {
        ...lessonState,
        lessonHasQuiz: true
        // isQuizCompleted: false,
      }
    }
    case "UNDO-LESSON": {
      return {
        ...lessonState,
        isQuizCompleted: false,
        isLessonDone: false,
      }
    }
    case "SHOW-LESSON": {
      return {
        ...lessonState,
        isQuizVisible: false,
        // isQuizCompleted: false,
      }
    }
    case "RESET-QUIZ": {
      return {
        ...lessonState,
        isQuizVisible: true,
        isQuizCompleted: false,
      }
    }
    case "QUIZ-ADD-FEEDBACK": {
      return {
        ...lessonState,
        isQuizVisible: true,
        message: action.payload.message,
        isQuizCompleted: false,

      }
    }
    case "SET-LESSON-COMPLETED": {
      return {
        ...lessonState,
        isQuizCompleted: true,
        isQuizLoading: false,
      }
    }
    default:
      return { ...lessonState }
  }
}

export const initialState = {
  isQuizVisible: false,
  isQuizCompleted: false,
  isQuizLoading: false,
  // isQuizFilledIn: false,
  lessonHasQuiz: false,
  lessonHasFiles: false,
  message: "",
  lessonContent: {},
  isLessonDone: false,
  isLoading: true,
}