import React from "react";
import { useAppContext } from "../../context/appProvider";
import LessonGridItem from "./LessonGridItem";
import * as Styled from "./styled";
import LoadingScreen from "../../components/LoadingScreen";
import { Link } from "gatsby";

const VideoCourse = (props: any) => {
  const ctx = useAppContext();
  const { state } = ctx;

  if (!state.user) {
    return <LoadingScreen />;
  }

  const takenLessons = state.videoCourse?.completedLessons;
  const { data } = props;
  const videoCourse = data?.videoCourses?.edges?.find(
    ({ node }: { node: any }) => node.productId === props.courseId,
  )?.node;

  if (!videoCourse) return <LoadingScreen />;
  return (
    <>
      <Styled.ListOverline>
        <Link to={"/en/user"}>Go Back</Link>
      </Styled.ListOverline>
      <Styled.Courtain />
      <Styled.LessonListerWrapper>
        <Styled.Title>
          <h1>
            Welcome! Thank you for purchasing {videoCourse.title}, you are going
            to love it
          </h1>
        </Styled.Title>


        {videoCourse.lessons.map((lesson: any, i: number) => (

          // <pre>{JSON.stringify(lesson, null, 2)}</pre>
          <LessonGridItem
            key={`${lesson.contentful_id}-${i}`}
            contentful_id={lesson.contentful_id}
            title={lesson.title}
            hasVideo={lesson.videoUrl ? true : false}
            hasFile={lesson.attachment?.length > 0 ? true : false}
            hasQuiz={lesson.quizQuestions && lesson.quizQuestions.length > 0}
            isCompleted={
              takenLessons?.find((l: any) => {
                return l.lessonId === lesson.contentful_id;
              })
                ? true
                : false
            }
          />
        ))}

        <Styled.Title>
          <h2>Almost here!</h2>
        </Styled.Title>
      </Styled.LessonListerWrapper>
    </>
  );
};

export default VideoCourse;
