import React, { useState } from "react";
import * as Styled from "./InputText.styled";


type InputTextType = {
  placeholder: any;
  anchor: any;
  callback: any;
  label: any;
  value: any;
  feedback: any;
  onBlur: any;
  isCorrect: any;
  showFeedback: any;
}
const InputText = (props: InputTextType) => {
  const {
    placeholder = "placeholder",
    anchor,
    callback,
    label,
    value,
    feedback,
    onBlur,
    isCorrect,
    showFeedback,
  } = props;
  if (!callback) return null;

  return (
    <div className={`c-input-text`}>

      {/* {label && <Styled.Label>{label}</Styled.Label>} */}
      <Styled.InputField
        id={anchor}
        value={value}
        placeholder={placeholder}
        onBlur={onBlur}
        disabled={showFeedback}
        isCorrect={isCorrect}
        autoComplete="off"
        showFeedback={showFeedback}
        onChange={(e) => {
          callback(e.target.value);
        }}
      />
      {showFeedback && feedback && (
        <Styled.Feedback>
          <p>{feedback}</p>
        </Styled.Feedback>
      )}
    </div>
  );
};
export default InputText;
