import React, { useEffect } from "react";
import LoadingScreen from "../../components/LoadingScreen";
import QuizForm from "../../components/QuizComponent/components/QuizForm/QuizForm";
import pathBuilder from "../../utils/pathBuilder";


type QuizWrapper = {
  quizQuestions: any,
  feedbackMode: any,
  set_answerArray: any,
  answerArray: any,
  isLoading: any,
}

const QuizWrapper = (props: QuizWrapper) => {
  const {
    quizQuestions,
    feedbackMode,
    set_answerArray,
    answerArray,
    isLoading,
  } = props;

  // console.log({ props })

  useEffect(() => {
    if (!answerArray || answerArray.length === 0) {
      set_answerArray(
        quizQuestions.map((e: any) => ({
          questionId: e.id,
          type: e.questionType,
          correctOption: e.correctOption ? e.correctOption : null,
          options: e.options,
          title: e.title,
          exactAnswer: e.exactAnswer,
          linkedResources: e.linkedResources?.map((element: any) => ({
            ...element,
            url: pathBuilder(
              element.node_locale,
              element.__typename,
              element.slug,
              // post.node.reservedPaths
            ),
          })),
        })),
      );
    }
  }, [quizQuestions, answerArray]);

  return (
    <div>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div>
          <QuizForm
            quizQuestions={quizQuestions}
            answerArray={answerArray}
            set_answerArray={set_answerArray}
            feedbackMode={feedbackMode}
          // onChange
          />
        </div>
      )}
    </div>
  );
};

export default QuizWrapper;
