import React from "react";
import * as Styled from "./user.styled";
import { useAppContext } from "../../context/appProvider";
import { ContainerWide, Row } from "../../components/System/Grids";
import Button from "../../components/UI/Button";

const Billing = (props) => {
  const ctx = useAppContext();
  const { state, getAccessTokenSilently } = ctx;
  // const { environment, data } = props;
  // const { groupCourses } = data;
  const userInvoices = state?.user?.invoices;

  const getInvoice = async (id) => {
    const token = await getAccessTokenSilently();
    window.location.href = `${process.env.GATSBY_BACKEND_URL}/invoices/getInvoice/${id}?token=${token}`;
  };
  return (
    <div>
      {userInvoices && (
        <ContainerWide id="invoices">
          <h2>My invoices</h2>
          {userInvoices && userInvoices.length > 0 && (
            <Row>
              {userInvoices.map((invoice) => {
                return (
                  <Styled.InvoiceRow className="row" key={invoice.id}>
                    <span>{invoice.id}</span>

                    <Button.PrimaryDefault
                      onClick={() => getInvoice(invoice.id)}
                    >
                      Download
                    </Button.PrimaryDefault>
                  </Styled.InvoiceRow>
                );
              })}
            </Row>
          )}
        </ContainerWide>
      )}
    </div>
  );
};

export default Billing;
