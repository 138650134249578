// TODO: Next step. fetch data only on load and once instead of on every refresh.
// TODO: Add file feature.

import React from "react";
import { Link } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Panel from "../../components/UI/Panel/Panel";
import VimeoIframe from "../../components/VimeoIframe/VimeoIframe";
// import { ContainerWide, Column, Row } from "../../components/System/Grids";
import * as Styled from "./styled";
import QuizWrapper from "./QuizWrapper";
import LoadingScreen from "../../components/LoadingScreen";
import useLessonQueries from './useLessonQueries'
import LessonButtonBlock from "./LessonButtonBlock";
import LessonDownoadBlock from "./LessonDownoadBlock";


const Lesson = (props: any) => {
  const { courseId, lessonId } = props;

  const {
    submitQuizAction,
    showQuizAction,
    goNextLessonAction,
    quizEngineResponse,
    goBackToTheoryAction,
    restartQuizAction,
    answerArray,
    set_answerArray,
    lessonState,
    undoLessonAction, }: {
      submitQuizAction: any,
      goNextLessonAction: any,
      undoLessonAction: any,
      showQuizAction: any,
      restartQuizAction: any,
      quizEngineResponse: any,
      goBackToTheoryAction: any,
      answerArray: any,
      set_answerArray: any,
      lessonState: any,

    } = useLessonQueries(lessonId, props.data.videoCourses, courseId);

  const {
    isQuizVisible,
    isQuizCompleted,
    isQuizLoading,
    message,
    isLessonDone,
    lessonHasQuiz,
    lessonHasFiles,
    lessonContent,
    isLoading,
  } = lessonState;


  if (isLoading) return <LoadingScreen />

  const {
    attachment,
    lesson,
    quizQuestions,
    shortIntroduction,
    title,
    videoUrl,
  } = lessonContent.fields;

  // TODO: Use message to display feedback

  return (
    <Styled.LessonWrapper>
      <Styled.LessonContainer>
        <Styled.LessonRow>
          <Styled.LessonBodyColumn w={65}>
            {!isQuizVisible && (
              <>
                {videoUrl && (
                  <Styled.VideoWrapper>
                    <VimeoIframe videoLink={videoUrl} title={title} />
                  </Styled.VideoWrapper>
                )}

                <Panel title="Lesson body" anchor="lesson-body">
                  {documentToReactComponents(lesson)}
                </Panel>
              </>
            )}
            {lessonHasQuiz && isQuizVisible && (
              <>
                {isQuizCompleted && <Panel title="restart quiz"><h3>This quiz has been completed and it is on feedback mode </h3>

                  <Styled.CompleteButton onClick={restartQuizAction}>
                    Restart quiz
                  </Styled.CompleteButton>
                </Panel>}

                <Panel title="Lesson Quiz" anchor="Lesson Quiz">


                  <QuizWrapper
                    quizQuestions={quizQuestions.map((e: any) => ({
                      ...e.fields,
                      id: e.sys.id,
                    }))}
                    feedbackMode={isQuizCompleted}
                    set_answerArray={set_answerArray}
                    answerArray={answerArray}
                    isLoading={isQuizLoading}
                  />
                </Panel>
              </>
            )}

            <LessonButtonBlock
              lessonHasQuiz={lessonHasQuiz}
              goNextLessonAction={goNextLessonAction}
              isQuizVisible={isQuizVisible}
              goBackToTheoryAction={goBackToTheoryAction}
              submitQuizAction={submitQuizAction}
              showQuizAction={showQuizAction}
              isQuizCompleted={isQuizCompleted}
              restartQuizAction={restartQuizAction}
              isLessonDone={isLessonDone}
              undoLessonAction={undoLessonAction}
              className="button-block-body"
            />
          </Styled.LessonBodyColumn>

          <Styled.HeadingColumn w={35}>


            <Styled.LessonTitle done={isLessonDone}>
              <Link to={`/en/user/course/${courseId}`}>← All</Link>
              <h1>{title}</h1>
            </Styled.LessonTitle>
            {shortIntroduction && <div>{shortIntroduction}</div>}


            {lessonHasFiles && <LessonDownoadBlock
              attachment={attachment}
              lessonId={lessonId}
              courseId={courseId}
            />}

            <LessonButtonBlock
              lessonHasQuiz={lessonHasQuiz}
              goNextLessonAction={goNextLessonAction}
              isQuizVisible={isQuizVisible}
              goBackToTheoryAction={goBackToTheoryAction}
              submitQuizAction={submitQuizAction}
              showQuizAction={showQuizAction}
              isQuizCompleted={isQuizCompleted}
              restartQuizAction={restartQuizAction}
              isLessonDone={isLessonDone}
              undoLessonAction={undoLessonAction}
              className="button-block-actions"
            />

          </Styled.HeadingColumn>
        </Styled.LessonRow>
      </Styled.LessonContainer>
    </Styled.LessonWrapper >
  );
};

export default Lesson;
