import styled from "styled-components";
// import { theme } from "../../../../theme/variables";
import mixins from "../../theme/mixins";
import { ContainerWide, Column, Row } from "../../components/System/Grids";
import MEDIA from "../../theme/helpers/mediaTemplates";

export const LessonWrapper = styled.div`
  /* background: ${(props) => props.theme.primary}; */
`;

export const VideoWrapper = styled.div`
  iframe {
    /* height: 460px; */
    /* ${MEDIA.TABLET`
    height: 60vw;
    `} */
  }
`;

export const CompleteButton = styled.button`
  padding: 0.4rem 1rem;
  border-radius: 40px;
  margin-top: 1rem;
  display: inline-block;
  width: fit-content;
  background-color: ${(props) => props.theme.contrast_primary};
  box-shadow: none;
  border: none;
  font-size: 1.1rem;
  color: white;
  margin-bottom: 0.5rem;
  cursor: pointer;
  ${mixins.shadowed.defaultLight.small};
  &:hover {
    background-color: ${(props) => props.theme.contrast_darkerOne};
    ${mixins.shadowed.primary.small};
  }
`;

export const LessonContainer = styled(ContainerWide)``;

export const LessonRow = styled(Row)`
  align-items: flex-start;
  gap: 32px;
  flex-wrap: nowrap;
  ${MEDIA.TABLET`
  flex-direction:column-reverse;`}
`;

export const LessonBodyColumn = styled(Column)``;
export const HeadingColumn = styled(Column)`
  position: sticky;
  border: 1px solid ${(props) => props.theme.default_halfUp};
  top: 18px;
  margin-top: 18px;
  background: white;
  padding: 12px;
  border-radius: 16px;
  ${MEDIA.TABLET`position:static;
    .button-block-actions{
      /* display:none; */
      position:relative;
    }
  `}
`;

export const ButtonSecondary = styled(CompleteButton)`
  background-color: ${(props) => props.theme.default_primary};
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 32px;
`;

type LessonTitle = {
  done: boolean;
};

export const LessonTitle = styled.div<LessonTitle>`
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => props.theme.default_halfUp};
  justify-content: space-between;
  border-radius: 100px;
  color: white;
  padding: 0.6rem 1.2rem;
  margin-bottom: 2rem;

  h1 {
    color: black;
    font-size: 0.9rem;
    position: relative;
    text-align: right;
    padding-left: 1.5rem;
    line-height: 1;
    margin: 0;
    &::after {
      content: "✅";
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      left: 0;
      top: 62%;
      transform: translateY(-50%);
      opacity: ${(props) => (props.done ? 1 : 0)};
    }
  }
`;

export const ColumnOne = styled;
