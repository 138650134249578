import React from 'react'
import * as Styled from "./styled"


function FeedbackBar() {
  return (
    <Styled.BarWrapper>
      <Styled.BarContainer>
        This is a new feature, let us know any feedback <a href={`mailto:info@thedutchonlineacademy.com?body=${encodeURI(
          `I have feedback about the user area`,
        )}&subject=${encodeURI(`Feedback user area`)}`}>via email</a>
      </Styled.BarContainer>
    </Styled.BarWrapper>
  )
}

export default FeedbackBar