import React from "react";
import { graphql } from "gatsby";
import UserTemplate from "../../../user";

const environment = {
  lang: "en",
  path: "/en/user",
  altHrefLang: [
    {
      lang: "en",
      path: "/en/user",
    },
  ],
  theme: "green",
};
const UserEn = (props) => {
  if (!props) return <h1>Loading</h1>;
  return <UserTemplate {...props} environment={environment} />;
};

export default UserEn;

export const UserQueryEn = graphql`
  {
    videoCourses: allContentfulCourses(filter: { node_locale: { eq: "en" } }) {
      edges {
        node {
          title
          contentful_id
          intro
          slug
          covers {
            gatsbyImageData(layout: CONSTRAINED, width: 400)
          }
          productId
          subtitle
          price
          lessons {
            contentful_id
            videoUrl
            title
            quizQuestions {
              title
            }
            attachment {
              type
            }
          }
          presentationVideoLink
          showFaq
        }
      }
    }
    groupCourses: allContentfulCourseGroup {
      edges {
        node {
          contentful_id
          starting
          title
          live_online_courses {
            title
            cover {
              main: gatsbyImageData(width: 480, layout: CONSTRAINED)
            }
          }
        }
      }
    }
    books: allContentfulBook(
      filter: { node_locale: { eq: "nl" } }
      sort: { fields: [createdAt], order: [DESC] }
    ) {
      edges {
        node {
          title
          slug
          price
          productId
          subtitle
          level
          fileIdSnipcart
          contentful_id
          numberOfPages
          node_locale
          studentLevel {
            level
          }
          image {
            fixed: gatsbyImageData(width: 150)
            main: gatsbyImageData(width: 200, layout: CONSTRAINED)
          }
          __typename
        }
      }
    }
    imgDesktop: file(relativePath: { eq: "index-banner-desktop-en.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imgMobile: file(relativePath: { eq: "index-banner-mobile-en.png" }) {
      childImageSharp {
        gatsbyImageData(width: 820, layout: CONSTRAINED)
      }
    }
  }
`;
