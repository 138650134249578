import styled from "styled-components";

export const BarWrapper = styled.div`
  max-width: 720px;
  padding: 0.7rem 1.2rem;

  margin: 0.5rem auto 0.5rem;
  border: 2px dashed ${(props) => props.theme.default_primary};
  color: ${(props) => props.theme.default_darkerOne};
  border-radius: 0.4rem;
`;
export const BarContainer = styled.div``;
