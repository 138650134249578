import styled from "styled-components";
// import MEDIA from "../../theme/helpers/mediaTemplates";
// import mixins from "../../theme/mixins";
type QuizQuestionContainerType = {
  feedbackMode: boolean;
  isQuestionCorrect: boolean;

}
export const QuizQuestionContainer = styled.div<QuizQuestionContainerType>`
  background: white;
  padding: 12px;
  border-radius: 30px;
  position: relative;
  margin-bottom: 30px;
  border: ${(props) =>
    props.feedbackMode && props.isQuestionCorrect
      ? `2px solid  ${props.theme.staticColors.success}`
      : props.feedbackMode
        ? `2px solid ${props.theme.staticColors.error}`
        : "none"};

  &::before {
    content: "";
    position: absolute;
    left: 10px;
    top: 10px;
    height: 20px;
    width: 20px;
    border-radius: 40px;
    background-color: ${(props) =>
    props.feedbackMode && props.isQuestionCorrect
      ? `${props.theme.staticColors.success}`
      : props.feedbackMode
        ? `${props.theme.staticColors.error}`
        : "none"};
  }
`;
