import { useEffect, useMemo, useReducer, useState } from "react";
import { useAppContext } from "../../context/appProvider";
import { lessonReducer, initialState } from "./lessonReducer";
import { navigate } from "gatsby";
import { postData } from "../../utils/helpers";
import { pushEvent } from "../../utils/tagManager";
import { createClient } from "contentful";

// Here we host all functions, with all async management & side effects.


// TODO: HERE WE PICK THE ATTACHMENT, GET DOWNLOAD LINK AND RETURN IT DISPATCHING TO THE REDUCER
const useLessonQueries = (
  lessonId: string,
  videoCourses: any,
  courseId: any) => {

  const ctx = useAppContext();
  const { getAccessTokenSilently, state, dispatch } = ctx;
  // START REDUCER
  const [lessonState, lessonDispatch]: [any, any] = useReducer(lessonReducer, initialState)

  // INITIALIZE DATA
  const [answerArray, set_answerArray] = useState([])
  const [quizEngineResponse, set_quizEngineResponse] = useState<string>("");

  // MOUNT LESSON GETTING ALL FROM  CONTENTFUL
  useEffect(() => {
    if (!createClient
      || !process.env.GATSBY_CONTENTFUL_SPACE_ID
      || !process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN
    ) return;
    const client = createClient({
      space: process.env.GATSBY_CONTENTFUL_SPACE_ID,
      environment: "master", // defaults to 'master' if not set
      accessToken: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN,
    });

    client
      .getEntry(lessonId)
      .then((entry: any) => {
        lessonDispatch({
          type: "SET-LESSON-CONTENT",
          payload: entry
        })
      })
      // eslint-disable-next-line no-console
      .catch(console.error);

    if (!state || !state.videoCourse) return

    if (state.videoCourse?.completedLessons?.find(
      (l: any) => l.lessonId === lessonId
    )) {
      lessonDispatch({ type: "SET-LESSON-DONE" })
    }

    if (
      state.videoCourse.quizzes?.find((q: any) => q.quizId === lessonId)?.answers
    ) {
      set_answerArray(
        state.videoCourse.quizzes.find((q: any) => q.quizId === lessonId).answers,
      );

      // console.log("ANSWER", state.videoCourse.quizzes.find((q: any) => q.quizId === lessonId))
      lessonDispatch({ type: "SET-QUIZ-AS-DONE" })

    }

  }, [lessonId, state.quizzes]);

  // console.log({ answerArray })

  const nextLesson = useMemo(() => {
    const videoCourseIndex = videoCourses.edges.find(
      (videoCourse: any) => videoCourse.node.productId === courseId,
    )?.node.lessons;

    const lessonIndexInArray = videoCourseIndex?.findIndex(
      (lesson: any) => lesson.contentful_id === lessonId,
    );
    return videoCourseIndex[lessonIndexInArray + 1]
      ? videoCourseIndex[lessonIndexInArray + 1]
      : false;
  }, [videoCourses, courseId, lessonId])


  const submitQuizAction = async () => { //Define submit quiz

    try {
      // TODO: Set quiz loading dispatch

      lessonDispatch({
        type: "SET-QUIZ-LOADING-STATUS",
        payload: true,
      })
      window.scrollTo({ top: 0, behavior: 'smooth' });

      const token = await getAccessTokenSilently();



      const submitQuizResponse = await postData({
        url: `${process.env.GATSBY_BACKEND_URL}/quizzes/auth-submit-quiz`,
        data: {
          quizId: lessonId,
          quiz: answerArray,
          currentUrl: window.location.href,
        },
        token,
      });

      const completeLessonResponse = await postData({
        url: `${process.env.GATSBY_BACKEND_URL}/lessons/complete-lesson`,
        data: { lessonId },
        token,
      });

      // TODO: GET FEEDBACK ON THE RESPONSE

      await dispatch({
        type: "COMPLETE-LESSON",
        payload: {
          token,
          lessonId,
        },
      });

      // lessonDispatch({ type: "QUIZ-SUBMITTED" });
      set_quizEngineResponse(submitQuizResponse);
      lessonDispatch({ type: "SET-LESSON-COMPLETED" });
      // TODO: TEST WHEN THIS FAILS, WHAT DO WE DO?
      // setTimeout(async () => {
      //   if (nextLesson.contentful_id) {
      //     return navigate(`../${nextLesson.contentful_id}`);
      //   }
      //   return navigate(`../`);
      // }, 2000);
    } catch (e) {

      set_quizEngineResponse(JSON.stringify(e));
      lessonDispatch({
        type: "QUIZ-ADD-FEEDBACK",
        payload: {
          message:
            "sorry, something went wrong, please contact us at info@thedutchonlineacademy.com",
        },
      });
      console.error("error", e);
    }
  };

  const goNextLessonAction = async () => {
    try {
      lessonDispatch({
        type: "SET-LESSON-LOADING"
      })
      const token = await getAccessTokenSilently();

      dispatch({
        type: "COMPLETE-LESSON",
        payload: {
          token,
          lessonId,
        },
      });

      await postData({
        url: `${process.env.GATSBY_BACKEND_URL}/lessons/complete-lesson`,
        data: { lessonId },
        token,
      });

      pushEvent({
        event: "quiz-action",
        action: "quiz-submitted",
        location: "lesson-quiz"
      });

      lessonDispatch({
        type: "SHOW-LESSON"
      })

      setTimeout(async () => {
        if (nextLesson.contentful_id) {
          return navigate(`../${nextLesson.contentful_id}`);
        }
        return navigate(`../`);
      }, 200);

    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("error", e);
    }
  }

  const goBackToTheoryAction = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    lessonDispatch({
      type: "SHOW-LESSON"
    })

  }

  const restartQuizAction = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    lessonDispatch({
      type: "RESET-QUIZ",
      payload: {
        quizId: lessonId,
      },
    });

    set_answerArray([]);
  }

  const undoLessonAction = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const token = await getAccessTokenSilently();

    await postData({
      url: `${process.env.GATSBY_BACKEND_URL}/lessons/undo-lesson`,
      data: { lessonId },
      token,
    });
    // Two dispatches, because the global data is stored on the user object as well :)
    lessonDispatch({ type: "UNDO-LESSON" })
    dispatch({ type: "UNDO-LESSON", payload: lessonId })
  }

  const showQuizAction = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    lessonDispatch({
      type: "SHOW-QUIZ"
    })
  }

  return {
    nextLesson,
    lessonDispatch,
    answerArray,
    set_answerArray,
    quizEngineResponse,
    submitQuizAction,
    goNextLessonAction,
    showQuizAction,
    restartQuizAction,
    undoLessonAction,
    goBackToTheoryAction,
    lessonState,
  };
};

export default useLessonQueries;
