import styled from "styled-components";
// import typhography from "../../../theme/mixins/typhography";
import MEDIA from "../../../../theme/helpers/mediaTemplates";

export const TextGroupWrapper = styled.div`
  background: white;
  padding: 12px;
  border-radius: 30px;
  > * {
    display: inline-block;
    padding: 0 4px;
    &:first-child {
      padding-right: 0px;
    }

    &:last-child {
      padding-left: 0px;
    }
  }
`;
export const InputGrid = styled.div`
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  ${MEDIA.PHONE`
    grid-template-columns: 1fr;
  `}
`;
