import styled from "styled-components";
import mixins from "../../theme/mixins";
import MEDIA from "../../theme/helpers/mediaTemplates";

export const Courtain = styled.div`
  width: 100%;
  background: ${(props) => props.theme.primary};
  height: 200px;
  margin-bottom: -150px;
  position: relative;
  z-index: 1;
`;

export const ListOverline = styled.div`
  position: absolute;
  z-index: 2;
  max-width: ${(props) => props.theme.sizes.contentMaxWidth};
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: block;
  padding-top: 1rem;
  font-weight: 600;
  color: white;
  * {
    color: inherit;
  }
  &::before {
    content: "← ";
  }
`;
export const LessonListerWrapper = styled.div`
  max-width: ${(props) => props.theme.sizes.contentMaxWidth};
  margin: auto;
  position: relative;
  margin: 3rem auto;
  padding: 0 0.5rem 3rem 0;
  &::after {
    content: "";
    position: absolute;
    left: 0px;
    width: 1px;
    top: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.default_primary};
    z-index: 0;
  }

  ${MEDIA.TABLET`
  
  margin: 0;
  margin-top: 3rem;
  margin-left: 2rem;
  `}
`;

export const Title = styled.div`
  background: white;
  padding: 1rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
  margin-left: 32px;
  ${mixins.shadowed.transparent.large};
  z-index: 2;
  position: relative;
  h1 {
    font-size: 1.5rem;
    font-weight: normal;
  }
`;

type LessonGridItemWrapper = {
  $isCompleted: boolean;
};
export const LessonGridItemWrapper = styled.div<LessonGridItemWrapper>`
  background: white;
  padding: 1rem;
  ${mixins.shadowed.transparent.large};
  display: flex;
  border-radius: 1rem;
  margin-bottom: 1rem;
  margin-left: 32px;
  position: relative;
  > * {
    font-size: 1rem;
    margin: 0;
    transition: 0.2s;
    font-weight: normal;
  }
  &:hover > * {
    color: ${(props) => props.theme.darkerOne};
  }
  &::after {
    content: "";
    width: 30px;
    height: 30px;
    background: black;
    border-radius: 40px;
    left: -32px;
    position: absolute;
    transform: translateX(-50%);
    background: ${(props) =>
      props.$isCompleted ? "green" : props.theme.default_primary};
    z-index: 2;
  }
`;
