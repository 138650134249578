import styled from "styled-components";
// import { theme } from "../../theme/variables";
import MEDIA from "../../theme/helpers/mediaTemplates";

// export const Wrapper = styled.div`
//   background: white;
//   padding: 80px 12px;
// `;

// export const Container = styled.div`
//   max-width: ${theme.contentMaxWidth};
//   margin: auto;
// `;
export const BoughtGroupCard = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  border-radius: 12px;
  overflow: hidden;
  padding: 12px;
  width: 50%;
  > .purchased-course-grid-item__image {
    margin: 0;
    width: 180px;
    height: 180px;
    box-shadow: 0 4px 8px #00000050;
  }

  > .purchased-course-grid-item__body {
    background: white;
    border-radius: 0px 12px 12px 0;
    box-shadow: 0 4px 8px #00000050;
    padding: 12px;
    h4 {
      font-weight: 700;
      font-size: 1.2rem;
      margin: 0;
    }
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  ${MEDIA.TABLET`
    flex-direction:column;
    width:100%;
    
  > .purchased-course-grid-item__image {
    margin:auto;
  }
    > .purchased-course-grid-item__body {
      border-radius: 12px;
      padding-top:42px;
      margin-top:-24px;

    }
    
  `}
`;

export const HorizontalNavigation = styled.ul`
  display: flex;
  margin: 0;
  position: relative;
  margin-top: 20px;
  flex-wrap: nowrap;
  overflow-x: auto;
  max-width: unset;
  white-space: nowrap;
  &::before {
    content: "";
    background: white;
    height: 1px;
    width: 200px;
    position: absolute;
    top: 5px;
    left: 0;
  }
  padding: 0;
  * {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    margin-right: 12px;
    padding: 14px 24px 8px 0;
  }
`;

export const InvoiceRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  /* margin: auto; */
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 12px;
`;

export const Table = styled.table`
  display: block;
  max-width: unset;
  overflow-x: auto;
  white-space: unset;
  border: 1px solid gray;

  tr {
    margin: 0;
    display: flex;
  }
  td,
  th {
    padding: 10px 5px;
    text-align: left;
    /* &:first-child { */
    border-bottom: 1px solid gray;
    margin: 0;
    width: 30%;
    min-width: 240px;
    border-right: 1px solid gray;
    &:first-child {
      width: 60%;
    }

    &:last-child {
      border-right: none;
      width: 10%;
    }
    /* border-top: 1px solid gray; */
    /* } */
    /* display: flex; */
  }
`;
