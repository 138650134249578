import styled from "styled-components";
import mixins from "../../theme/mixins";

type LessonGridItemWrapper = {
  $isCompleted: boolean;
};

export const LessonGridItemWrapper = styled.div<LessonGridItemWrapper>`
  background: white;
  padding: 1rem;
  ${mixins.shadowed.transparent.large};
  display: flex;
  border-radius: 1rem;
  margin-bottom: 1rem;
  margin-left: 32px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > * {
    font-size: 1rem;
    margin: 0;
    transition: 0.2s;
    font-weight: normal;
  }
  &:hover > * {
    color: ${(props) => props.theme.darkerOne};
  }
  &::after {
    content: "";
    width: 30px;
    height: 30px;
    background: black;
    border-radius: 40px;
    left: -32px;
    position: absolute;
    transform: translateX(-50%);
    background: ${(props) =>
      props.$isCompleted ? "green" : props.theme.default_primary};
    z-index: 2;
  }
`;

export const Title = styled.h3``;

export const BadgeContainer = styled.div`
  display: flex;
  gap: 12px;
`;
export const HasVideoBadge = styled.div`
  display: block;
  padding: 0;
  background: ${(props) => props.theme.default_lighterOne};
  border-radius: 12px;
  padding: 4px;
  /* width: 30px;
  height: 30px; */
  display: flex;
  align-items: center;
  justify-content: space-around;
  svg {
    width: 32px;
    height: 32px;
  }
`;
export const HasQuizBadge = styled.div`
  display: block;
  padding: 0;
  background: ${(props) => props.theme.default_lighterOne};
  border-radius: 12px;
  padding: 4px;
  /* width: 30px;
  height: 30px; */
  display: flex;
  align-items: center;
  justify-content: space-around;
  svg {
    width: 32px;
    height: 32px;
  }
`;
export const HasAttachmentBadge = styled.div`
  display: block;
  padding: 0;
  background: ${(props) => props.theme.default_lighterOne};
  border-radius: 12px;
  padding: 4px;
  /* width: 30px;
  height: 30px; */
  display: flex;
  align-items: center;
  justify-content: space-around;
  svg {
    width: 32px;
    height: 32px;
  }
`;
