/* eslint-disable prettier/prettier */
import React from "react";
import { useAppContext } from "../../context/appProvider";
import BookGridItem from "../../components/BookGridItem/BookGridItem";
import pathBuilder from "../../utils/pathBuilder";
import { Link } from "gatsby";

import {
  ContainerWide,
  Row,
  Column,
} from "../../components/System/Grids";

const Books = (props) => {
  const ctx = useAppContext();
  const { state } = ctx;
  const { environment, data } = props;
  const { books } = data;


  const purchasedBooks = state?.user?.purchased
    ? books?.edges?.filter((book) =>
      state?.user?.purchased?.find(
        (bought) => bought.productId === book.node.productId,
      ),
    )
    : false;
  return (
    <ContainerWide id="books">
      <h2>My books</h2>
      {purchasedBooks && purchasedBooks.length > 0 ? (
        <Row>
          {purchasedBooks.map(({ node }, index) => {
            return (
              <BookGridItem
                key={index}
                title={node.title}
                price={node.price}
                subtitle={node.subtitle}
                level={node.level}
                productId={node.productId}
                studentLevel={node.studentLevel}
                numberOfPages={node.numberOfPages}
                fileIdSnipcart={node.fileIdSnipcart}
                image={node.image}
                contentful_id={node.contentful_id}
                slug={pathBuilder(node.node_locale, node.__typename, node.slug)}
                lang={environment.lang}
                urlSnipcart={environment.lang === "en" ? "/en/shop" : "/shop"}
              />
            );
          })}
        </Row>
      ) : (
        <Row>
          <Column>
            <h3>You do not have any book</h3>
            <p>
              Here you would see all the books you purchased, and you would be
              able to download the latest version of them.
            </p>
            <Link to="/en/shop">Go get some</Link>
          </Column>
        </Row>
      )}
    </ContainerWide>
  );
};

export default Books;
