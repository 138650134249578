/* eslint-disable prettier/prettier */
import React from "react";
import { useAppContext } from "../../context/appProvider";
import PurchasedVideoCourseGridItem from "./PurchasedVideoCourseGridItem";
import {
  Container,
  Row,
} from "../../components/System/Grids";



const VideoCourses = (props) => {
  const ctx = useAppContext();
  const { state } = ctx;
  const { data } = props;
  const { videoCourses } = data;
  const purchasedVideoCourses = state?.user?.purchased
    ? videoCourses?.edges?.filter((vidCourse) =>
      state?.user?.purchased?.find(
        (bought) => bought.productId === vidCourse.node.productId,
      ),
    )
    : false;


  return (
    <Container>
      <h2>Video courses</h2>
      <Row>
        {purchasedVideoCourses &&
          purchasedVideoCourses?.map(({ node }) => {
            const progress =
              100 * node.lessons?.filter(lesson => state.videoCourse?.completedLessons?.find(completedLesson => completedLesson.lessonId === lesson.contentful_id)).length / node.lessons.length

            return (
              <PurchasedVideoCourseGridItem
                key={node.productId}
                videoCourse={node}
                progress={progress}
                covers={node.covers}
                image={node.covers[0].gatsbyImageData}
                productId={node.productId}
                title={node.title}
              />
            );
          })}
      </Row>
    </Container>)
};

export default VideoCourses;
