import React, { useState } from 'react'
import { useAppContext } from "../../context/appProvider";
import { postData } from "../../utils/helpers";
import {
  DowloadButton,
  DownloadSection
} from "./LessonDownloadBlock.styled"

type LessonDownoadBlock = {
  attachment: any,
  lessonId: string,
  courseId: string,
}

type LessonDownloadItem = {
  fileId: string;
  fileLink: string
}
function LessonDownoadBlock(props: LessonDownoadBlock) {
  const {
    attachment,
    lessonId,
    courseId,
  } = props;

  const ctx = useAppContext();
  const { getAccessTokenSilently } = ctx;

  const [isLoading, set_isLoading] = useState(false);
  const [linksObjectCache, set_linksObjectCache] = useState<any>({});




  // const handleDownloadClick = async (fileId: string, linksObjectCache: any, set_linksObjectCache: any) => {
  const handleDownloadClick = async (fileId: string) => {
    try {
      if (!fileId) throw Error("No ID?")
      // Short return if the items have been loaded already
      if (linksObjectCache && linksObjectCache[fileId]) {
        window.location.href = linksObjectCache[fileId];
        return
      }

      // Otherwise, get the stuff to download the lesson attachments
      set_isLoading(true);
      const token = await getAccessTokenSilently();
      const response = await postData({
        url: `${process.env.GATSBY_BACKEND_URL}/media/serve`,
        data: {
          lessonId: lessonId,
          courseId: courseId
        },
        token,
      });

      // Response is an array of objects with fileId:fileId , fileLink:fileLink.
      // We transpond to be an object to be able to do object[fileId]=fileLink

      const linksObject: any = {};
      response.fileArray.forEach((fileObj: LessonDownloadItem) => linksObject[fileObj.fileId] = fileObj.fileLink)

      set_linksObjectCache(linksObject);
      window.location.href = linksObject[fileId];
      set_isLoading(false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({ e });
    }
  };

  return (
    <DownloadSection disabled={isLoading}>
      <h2>Download the attachments</h2>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%"
        }}>
        {attachment.map((file: any) => <div
          style={{
            maxWidth: "33%",
            padding: ".4rem"
          }}>

          <DowloadButton
            // href={file.url}
            // onClick={() => handleDownloadClick(file.public_id, linksObjectCache, set_linksObjectCache)}
            onClick={() => handleDownloadClick(file.public_id)}
          >
            <img
              src={file.url}
              style={{ maxWidth: "100%" }}
            />
            <span>Download</span>
          </DowloadButton>
        </div>)}

      </div>
    </DownloadSection>
  )
}

export default LessonDownoadBlock