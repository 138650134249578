declare global {
  interface Window {
    dataLayer: any[];
    pocket: any;
  }
}

type pushEventType = {
  event: string;
  location: string;
  action: string;
  label?: string;
};

const pushEvent = ({ event, location, action, label }: pushEventType) => {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: event,
      data: {
        location,
        action,
        label,
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error({ "error in the tracking quiz js": e });
  }
};
export { pushEvent };
