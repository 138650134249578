/* eslint-disable prettier/prettier */
import React from "react";
import Layout from "../components/Layout/Layout";
import LoginButton from "../components/LoginButton";
import { useAppContext } from "../context/appProvider";
import PageIntro from "../components/PageIntro/PageIntro";
import { Container, Row } from "../components/System/Grids";
import { Router } from "@reach/router"
import Admin from '../templates/admin'
import Books from "../templates/user-books";
import LiveCourses from "../templates/user-group-courses";
import Billing from "../templates/user-billing";
import VideoCourses from '../templates/user-courses-all';
import VideoCourse from '../templates/user-courses-timeline';
import Lesson from "../templates/user-courses-lesson";
import LoadingScreen from "../components/LoadingScreen";
import FeedbackBar from "../components/FeedbackBar"

const UserTemplate = (props) => {
  const ctx = useAppContext();
  const { state } = ctx;
  const { environment, data } = props;
  const { groupCourses } = data;

  const seo = {};
  seo.title = `My account at The Dutch Online Academy`;
  environment.seo = seo;
  if (state?.user?.loading) return <Layout environment={environment}><LoadingScreen /></Layout>
  if (state?.user?.authenticated) {
    return (
      <Layout environment={environment} isSticky={false}>
        <FeedbackBar />
        <Router>

          {state?.user.user["https://thedutchonlineacademy.com/app_metadata"]?.isAdmin && <Admin path="/en/user/admin/*" />}

          <VideoCourses
            path="/en/user"
            data={data}
            environment={environment}
          />

          <LiveCourses
            path="/en/user/group-courses"
            data={data}
            environment={environment}
            groupCourses={groupCourses}
          />

          <VideoCourse
            path="/en/user/course/:courseId"
            data={data}
            environment={environment}
          />
          <Lesson
            path="/en/user/course/:courseId/lesson/:lessonId"
            data={data}
            environment={environment}
          />
          <Books path="/en/user/books" data={data} environment={environment}></Books>

          <Billing
            path="/en/user/billing"
            data={data}
            environment={environment}
            groupCourses={groupCourses} />

        </Router>
      </Layout >
    );
  } else {
    return (
      <Layout environment={environment}>
        <PageIntro>
          <h1>
            Hello! <br />
            Please verify your email to access to all your video coures, books, courses and invoices!
          </h1>
        </PageIntro>
        <Container>
          <Row align="center">
            <LoginButton type="giant" location="user-page" message="Authenticate email" />
          </Row>
        </Container>
        <br />
      </Layout>
    );
  }
};

export default UserTemplate;
