import React from "react"
import { SectionContainer, Tag } from "./Panel.styled";

const Panel = ({ children, title, anchor }: any) => {
  if (!children) return null;
  const classNames = `c-panel`;
  return (
    <SectionContainer id={anchor} className={classNames}>
      {title && <Tag>{title}</Tag>}
      {children}
    </SectionContainer>
  );
};
export default Panel;
