import React from "react";
import {
  InputCheckboxWrapper,
  Label,
  Feedback,
} from "./InputCheckbox.styled";
import matchExactAnswer from "../../utils/stringMatch";

type InputCheckboxType = {
  onChangeAction: (value: any) => void;
  fieldValue: string;
  correctValue: string;
  showFeedback: boolean;
  selectedValue: string;
};

const InputCheckbox = (props: InputCheckboxType) => {
  const {
    onChangeAction,
    fieldValue,
    correctValue,
    showFeedback,
    selectedValue,
  } = props;


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (showFeedback) return;
    onChangeAction(value);
  }

  // TODO: here we manage the feedback output status, untouched, modified, correct & incorrect.
  if (!onChangeAction) return null;

  const isCorrectAnswer =
    selectedValue != "" && matchExactAnswer(correctValue, fieldValue);

  const classNames = `c-input-text`;
  return (
    <Label>
      <InputCheckboxWrapper
        isCorrectAnswer={isCorrectAnswer}
        showFeedback={
          (showFeedback && matchExactAnswer(correctValue, fieldValue)) ||
          (showFeedback && matchExactAnswer(selectedValue, fieldValue))
        }
        checked={selectedValue === fieldValue}
        disabled={false}
      >
        <label htmlFor={fieldValue} style={{ display: "none" }}>{fieldValue}</label>
        <input
          checked={selectedValue === fieldValue}
          className={classNames}
          id={fieldValue}
          type={"radio"}
          value={fieldValue}
          onChange={handleInputChange}
          autoComplete="off"

        />
        {fieldValue}
      </InputCheckboxWrapper>
    </Label>
  );
};
export default InputCheckbox;
