/* eslint-disable prettier/prettier */
import React from "react";
import { useAppContext } from "../../context/appProvider";
import { Link } from "gatsby";
import * as Styled from "../../user/user.styled";
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  ContainerWide,
  Row,
  Column,
} from "../../components/System/Grids";

const LiveCourses = (props) => {
  const ctx = useAppContext();
  const { state } = ctx;
  const { data } = props;
  const { groupCourses } = data;


  const purchasedGroupCourses = state?.user?.purchased
    ? groupCourses?.edges?.filter((courseGroup) =>
      state?.user?.purchased?.find(
        (bought) => courseGroup.node.contentful_id === bought.productId
      ),
    ).filter(e => e.node?.live_online_courses)
    : false
  return (
    <ContainerWide id="group-courses">
      <h2>My Courses</h2>

      <Row>
        {purchasedGroupCourses && purchasedGroupCourses.length > 0 ?
          purchasedGroupCourses?.map(({ node }, i) => {
            return (
              <Styled.BoughtGroupCard key={`${node.contentful_id}-${i}`} className="c-purchased-course-grid-item">
                <GatsbyImage className="purchased-course-grid-item__image" image={node.live_online_courses[0].cover.main} alt={`${node.live_online_courses[0].title}-cover`} />
                <div className="purchased-course-grid-item__body">

                  <h4>{node.live_online_courses[0].title}</h4>
                  <p>Group: {node.title}</p>
                  <span>Starting: {node.starting}</span>
                </div>

              </Styled.BoughtGroupCard>
            );
          }) : (
            <Column>
              <h3>You haven&apos;t subscribed to any course</h3>
              <p>Here you would see all the group courses.</p>
              <Link to="/en/live-courses">
                Go get some
              </Link>
            </Column>)
        }
      </Row>
    </ContainerWide>)
};

export default LiveCourses;
