import React from "react";
import InputTextGroup from "../InputTextGroup/InputTextGroup";
import InputCheckboxGroup from "../InputCheckboxGroup/InputCheckboxGroup";
import { pushEvent } from "../../../../utils/tagManager";



type QuizMultipleChoice = {
  questionType: "multiple_choice";
  id: string;
  title: string;
  options: string[];
  correctOption: string;
  // inputArray: string[];

}

type QuizFillTheGapType = {
  id: string;
  questionType: "fill_the_gap";
  exactAnswer: string;
  title: string;
  linkedResources: string;
}
type QuizQuestionType = QuizFillTheGapType | QuizMultipleChoice;


type QuizForm = {
  quizQuestions: QuizQuestionType[];
  answerArray: {
    answer: string;
  }[];
  set_answerArray: any;
  feedbackMode: boolean;
}


const QuizForm = ({
  quizQuestions,
  answerArray,
  set_answerArray,
  feedbackMode,
}: QuizForm) => {
  return (
    <div>
      {quizQuestions.map((e: QuizQuestionType, index: number) => {
        return (
          <div key={e.id}>
            {e.questionType === "multiple_choice" && (
              <InputCheckboxGroup
                showFeedback={feedbackMode}
                correctValue={e.correctOption}
                question={e.title}
                selectedValue={answerArray[index]?.answer}
                inputArray={e.options?.map((option) => ({
                  fieldValue: option,
                }))}
                onChange={(val) => {
                  pushEvent({
                    event: "quiz-action",
                    action: "answer-selected",
                    location: `question-n:${index}`,
                  });
                  set_answerArray(
                    answerArray.map((e, i) => {
                      if (i === index) return { ...e, answer: val };
                      return e;
                    }),
                  );
                }}
              />
            )}
            {e.questionType === "fill_the_gap" && (
              <InputTextGroup
                feedbackMode={feedbackMode}
                exactAnswer={e.exactAnswer}
                title={e.title}
                linkedResources={e.linkedResources}
                selectedValue={answerArray[index]?.answer}
                onBlur={() => {
                  pushEvent({
                    event: "quiz-action",
                    action: "answer-selected",
                    location: `question-n:${index}`,
                  });
                }}
                onChange={(val: string) =>
                  set_answerArray(
                    answerArray.map((e, i) => {
                      if (i === index) return { ...e, answer: val };
                      return e;
                    }),
                  )
                }
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default QuizForm;
