import styled from "styled-components";

export const CommentCard = styled.div`
  display: block;
  border: 1px solid gray;
  border-radius: 40px;
  padding: 2rem 1rem;
`;

export const CommentActions = styled.div`
  border-top: 1px solid gray;
  display: flex;
  padding: 1rem 0;
`;

export const ResponseActions = styled.div`
  border-top: 1px solid gray;
  display: flex;
  padding: 1rem 0;
`;
