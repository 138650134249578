import React from "react";
import InputCheckbox from "../InputCheckbox/InputCheckbox";
import * as Styled from "./InputCheckboxGroup.styled";
import * as SharedStyled from "../../Quiz.styled";
import matchExactAnswer from "../../utils/stringMatch";

type InputCheckboxGroupType = {
  inputArray: { fieldValue: string }[];
  onChange: (value: string) => void;
  selectedValue: string;
  showFeedback: boolean;
  correctValue: string;
  question: string;
}
const InputCheckboxGroup = (
  props
    : InputCheckboxGroupType) => {
  const { inputArray,
    onChange,
    selectedValue = "",
    showFeedback,
    correctValue,
    question, } = props;
  if (!question || !onChange || !inputArray) return null;

  const isQuestionCorrect =
    selectedValue && matchExactAnswer(selectedValue, correctValue)
      ? true
      : false;

  return (
    <SharedStyled.QuizQuestionContainer
      className="c-input-checkbox-group"
      feedbackMode={showFeedback}
      isQuestionCorrect={isQuestionCorrect}
    >
      <p>{question}</p>
      <Styled.InputGrid>
        {inputArray.map((inputItem: any) => (
          <InputCheckbox
            fieldValue={inputItem.fieldValue}
            key={inputItem.fieldValue}
            onChangeAction={onChange}
            selectedValue={selectedValue}
            correctValue={correctValue}
            showFeedback={showFeedback}
          />
        ))}
      </Styled.InputGrid>
    </SharedStyled.QuizQuestionContainer>
  );
};

export default InputCheckboxGroup;
