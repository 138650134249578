const matchExactAnswer = (
  a: string | string[],
  b: string | string[],
): boolean => {
  if (!a || !b) return false;
  if (Array.isArray(a) && Array.isArray(b)) {
    return (
      JSON.stringify(a.join(","))
        .replace(/"\s+|\s+"/g, '"')
        .toLowerCase()
        .trim() ===
      JSON.stringify(b.join(","))
        .replace(/"\s+|\s+"/g, '"')
        .toLowerCase()
        .trim()
    );
  }

  return (
    JSON.stringify(a)
      .replace(/"\s+|\s+"/g, '"')
      .toLowerCase()
      .trim() ===
    JSON.stringify(b)
      .replace(/"\s+|\s+"/g, '"')
      .toLowerCase()
      .trim()
  );
  return false;
};

export default matchExactAnswer;
