import React from 'react';
import * as Styled from "./styled";

function LoadingScreen() {
  return (
    <Styled.LoadingWrapper>
      <Styled.LoadingMessage>

        Loading
      </Styled.LoadingMessage>
    </Styled.LoadingWrapper>
  )
}

export default LoadingScreen