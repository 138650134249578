import React from "react";
import { Link } from "gatsby";
import * as Styled from "./styled";
import { GatsbyImage } from "gatsby-plugin-image";
import { Button } from "../../components/UI/Buttons";

const PurchasedVideoCourseGridItem = ({
  // videoCourse,
  progress,
  covers,
  productId,
  title,
  image,
}) => {
  return (
    <Styled.PurchasedVideoCourseGridItemWrapper key={productId}>
      <Styled.TileImage as={GatsbyImage} image={image} alt={title} />
      <Styled.Body>
        <Styled.Title>{title}</Styled.Title>
        <Styled.ProgressBar progress={progress} />
        <br />

        <Button as={Link} to={`/en/user/course/${productId}`}>
          Continue
        </Button>
      </Styled.Body>
    </Styled.PurchasedVideoCourseGridItemWrapper>
  );
};

export default PurchasedVideoCourseGridItem;
