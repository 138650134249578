const zeroPad = (num, places) => String(num).padStart(places, "0");

const parseTimestamp = (fullDateString) => {
  const [date, time] = fullDateString.split(" @ ");

  const piecesDate = date.split("/");

  const dateString = [
    piecesDate[2],
    zeroPad(piecesDate[1], 2),
    zeroPad(piecesDate[0], 2),
  ];
  const piecesTime = time.split(":");
  const finalString = new Date(
    `${dateString.join("-")}T${zeroPad(piecesTime[0], 2)}:${zeroPad(
      piecesTime[1],
      2,
    )}:${zeroPad(piecesTime[2], 2)}`,
  );
  return finalString.getTime();
};

export default parseTimestamp;
