import { css } from "styled-components";
import { theme } from "../variables";
import MEDIA from "../helpers/mediaTemplates";

const overlines = css`
  font-family: ${theme.fontHeaders};
`;
const body = css`
  font-family: ${theme.fontHeaders};
  line-height: 1.5;
  p {
    font-weight: 200;
    padding-bottom: 12px;
  }
  a {
    border-bottom: 2px solid ${(p) => p.theme.primary};
    transition: 0.1s;
    cursor: pointer;
    &:hover {
      border-radius: 3px;
      background: ${(p) => p.theme.primary};
    }
  }
  ${MEDIA.MINIPHONE`
  font-size:0.9rem;
  `}
`;

const allStyled = {
  overlines,
  body,
};

export default allStyled;
