import styled from "styled-components";
// import typhography from "../../../theme/mixins/typhography";
// import MEDIA from "../../../theme/helpers/mediaTemplates";

type InputFieldProps = {
  isCorrect: boolean;
  showFeedback: boolean;

}


// TODO:REVIEW IF THIS MAKES SENSE (REMOVED PROPS CALL BELOW)

export const InputField = styled.input<InputFieldProps> `
  padding: 0.5rem 0.4rem;
  background ${(props) =>
    props.disabled
      ? props.theme.default_primary
      : props.theme.default_lighterOne};
  box-shadow:none;
  border-radius:4px;
  border: 2px solid ${(props) =>
    props.showFeedback === true
      ? props.isCorrect
        ? `${props.theme.staticColors.success}`
        : `${props.theme.staticColors.error}`
      : `${props.theme.default_lighterOne}`};


${(props) =>
    props.showFeedback === true
      ? props.isCorrect
        ? `
      border: 2px solid ${props.theme.staticColors.success};
      box-shadow: 0px 0px 3px ${props.theme.staticColors.success};`
        : `
      border: 2px solid ${props.theme.staticColors.success};
      box-shadow: 0px 0px 3px ${props.theme.staticColors.error};`
      : `
      border: 2px solid ${props.theme.default_primary};`};
`;

export const Label = styled.label`
  display: block;
  font-size: 0.8rem;
  margin-bottom: 0.3rem;
`;

export const Feedback = styled.div`
  display: block;
  margin-top: 0.3rem;
  * {
    font-size: 0.7rem;
    line-height: 1;
    margin-top: 0;
    color: ${({ theme }) => theme.default_darkerTwo};
  }
`;
