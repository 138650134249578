import React, { useEffect, useState } from "react";
import { Table } from "../../user/user.styled";
import { useAppContext } from "../../context/appProvider";
import { getData } from "../../utils/helpers";
import { Router } from "@reach/router";
import { Link } from "gatsby";
import CommentsAdmin from "./components/CommentsAdmin";
import parseTimestamp from "../../utils/parseTimestamps";

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function sortingFunction() {
  // if (type === "date") {
  return (a, b) => {
    if (!a.timestamp) return 1;
    if (!b.timestamp) return -1;
    const dateA = parseTimestamp(a.timestamp);
    const dateB = parseTimestamp(b.timestamp);
    if (dateA > dateB) return -1;
    return +1;
  };
  // }
}

const groupBy = (x, f) => {
  const asObject = x.reduce(
    (a, b, i) => ((a[f(b, i, x)] ||= []).push(b), a),
    {},
  );
  return Object.keys(asObject)
    .map((key) => asObject[key])
    .flat();
};

const Orders = ({ arrOfOrders, filter, set_filter, sorting, set_sorting }) => {
  const arrayOfOrdersFiltered =
    sorting === "date"
      ? arrOfOrders
        .sort(sortingFunction())
        .filter((e) => filter === "" || e.productId?.name === filter)
      : groupBy(
        arrOfOrders
          .sort(sortingFunction())
          .filter((e) => filter === "" || e.productId?.name === filter),
        (e) => e.userId,
      );

  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "32px",
          alignItems: "center",
          marginBottom: "20px",
          backgroundColor: "darkgray",
        }}
      >
        <div>Filters {filter}</div>
        <select
          id="filters"
          onChange={(e) => set_filter(e.target.value)}
          style={{ maxWidth: "100%", height: "40px" }}
        >
          <option value={""}>Select the thing you want to filter</option>
          {arrayOfOrdersFiltered
            .map((o) => o.productId?.name)
            .filter(onlyUnique)
            .map((e, i) => (
              <option value={e} key={i}>
                {e}
              </option>
            ))}
        </select>
        <div>Sorting {sorting}</div>

        <select
          id="sorting"
          onChange={(e) => set_sorting(e.target.value)}
          style={{ maxWidth: "100%", height: "40px" }}
        >
          <option value="date">Date</option>
          <option value="userEmail">User Email</option>
        </select>
      </div>

      <Table>
        <thead>
          <tr>
            <th>Product</th>
            <th>User</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {arrayOfOrdersFiltered
            .filter((e) => filter === "" || e.productId?.name === filter)
            .map((o, i) => (
              <tr key={i}>
                {/* <td>{o.productId?.id}</td> */}
                <td>{o.productId?.name}</td>
                <td>
                  <a href={`mailto:${o.userId}`}>{o.userId}</a>
                </td>
                <td>{o.timestamp}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};
const Admin = () => {
  const ctx = useAppContext();
  const { state, getAccessTokenSilently } = ctx;
  const [arrOfOrders, set_arrOfOrders] = useState([]);
  const [filter, set_filter] = useState("");
  const [sorting, set_sorting] = useState("date");

  // TODO: copy this to the Comments Admin and make the admin panel work
  useEffect(() => {
    const fetchData = async () => {
      const token = await getAccessTokenSilently();
      const apiResponse = await getData({
        url: `${process.env.GATSBY_BACKEND_URL}/admin/get-course-signups`,
        token,
      });
      if (apiResponse) {
        set_arrOfOrders(apiResponse);
      }
    };
    fetchData();
  }, [state]);

  if (state.user.isAdmin) {
    return (
      <div>
        <ul
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0",
            borderBottom: "1px solid black",
          }}
        >
          <li
            style={{ listStyle: "none", padding: "1rem", marginLeft: "1rem" }}
          >
            <Link to="/en/user/admin/orders">Orders</Link>
          </li>
          <li
            style={{ listStyle: "none", padding: "1rem", marginLeft: "1rem" }}
          >
            <Link to="/en/user/admin/comments">Comments</Link>
          </li>
        </ul>
        <Router>
          <Orders
            arrOfOrders={arrOfOrders}
            filter={filter}
            set_filter={set_filter}
            sorting={sorting}
            set_sorting={set_sorting}
            path="orders"
          />
          <CommentsAdmin path="comments" />
        </Router>
      </div>
    );
  } else {
    return <h1>Unauthorized</h1>;
  }
};

export default Admin;
