import styled from "styled-components";

type DownloadSection = {
  disabled: Boolean;
};
export const DownloadSection = styled.div<DownloadSection>`
  border: 1px solid ${(props) => props.theme.default_primary};
  margin-top: 1rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? "wait" : "auto")};
`;

export const DowloadButton = styled.button`
  cursor: pointer;
  background: ${(props) => props.theme.default_lighterTwo};
  border: none;
  box-shadow: none;
  padding: 0 0 3px 0;
  margin: 0;
  border-radius: 0;
  span {
    display: block;
  }
`;
