import styled from "styled-components";
// import typhography from "../../../theme/mixins/typhography";
import MEDIA from "../../../../theme/helpers/mediaTemplates";

export const CheckboxGroupWrapper = styled.div`
  background: white;
  padding: 12px;
  border-radius: 30px;
`;
export const InputGrid = styled.div`
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  ${MEDIA.PHONE`
    grid-template-columns: 1fr;
  `}
`;
